import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import '../App.css';
import 'react-tabs/style/react-tabs.css';
import { AuthContext } from '../context/AuthContext';
import { Tab, TabPanel, Tabs, TabList } from 'react-tabs';

import Api from '../Api';
const Season = () => {
  const { user, initializeAuth } = useContext(AuthContext);
  const [userStatistics, setUserStatistics] = useState([]);
  const [playerStatistics, setPlayerStatistics] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const fetchStatstics = async () => {
    await initializeAuth();
    try {
      var response = await Api.getStatistics(pageIndex);
      setUserStatistics(response.data);
    } catch (error) {
      console.error("Error fetching statistics:", error);
    }
  };
  useEffect(() => {
    fetchStatstics();
  }, []); 
  return (<Tabs>
      <TabList>
        <Tab>
            Лига
        </Tab>
        <Tab>
            Бомабардиры
        </Tab></TabList>
        <TabPanel>
            {userStatistics && (
            <table border="1" style={{ marginTop: '20px', width: '100%' }}>
                <thead>
                <tr>
                    <th>Place</th>
                    <th>UserName</th>
                    <th>Points</th>
                    <th>Goals Count</th>
                </tr>
                </thead>
                <tbody>
                {userStatistics.map((user, index) => (
                    <tr key={index}>
                    <td>{user.place}</td>
                    <td>{user.userName}</td>
                    <td>{user.points}</td>
                    <td>{user.goals}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            )}
        </TabPanel>
        <TabPanel>
            {playerStatistics && (
            <table border="1" style={{ marginTop: '20px', width: '100%' }}>
                <thead>
                <tr>
                    <th>UserId</th>
                    <th>Player</th>
                    <th>Goals Count</th>
                </tr>
                </thead>
                <tbody>
                {playerStatistics.map((user, index) => (
                    <tr key={index}>
                    <td>{user.userId}</td>
                    <td>{user.playerId}</td>
                    <td>{user.goals}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            )}
        </TabPanel>
  </Tabs>);
};
export default Season;