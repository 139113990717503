
import axios from 'axios';
import {BASE_URL} from './constants'
const Api = {
    selectBundle : (bundleId) => {
        return axios.post(`${BASE_URL}/api/players/bundles/${bundleId}`);
    },
    getBundles : () => {
        return axios.get(`${BASE_URL}/api/players/bundles`);
    },
    loginTelegram : (initData) => {
        return axios.post(`${BASE_URL}/api/login/telegram`, {
            initData: initData,
          })
    },
    login : (userId) => {
        return axios.post(`${BASE_URL}/api/login`, {
            id: userId,
          })
    },
    getUsers : () => {
        return axios.get(`${BASE_URL}/api/users`);
    },
    addUser : (userName) => {
        return axios.post(`${BASE_URL}/api/users`, {
            userName: userName,
          })
    },
    getUser : () => {
        return axios.get(`${BASE_URL}/api/users/current`);
    },
    getToken : (userData) => {
        return axios.post(`${BASE_URL}/api/login`, userData)
    },
    getCurrentRound : () => {
        return axios.get(
            `${BASE_URL}/api/users/rounds/current`
          );
    },
    getOpponents : (roundId, competitionId, page) => {
        let url = `${BASE_URL}/api/users/rounds/${roundId}`;
      if (competitionId) {
        url += `/competitions/` + competitionId;
      }
      url += `/${page}`;
      return axios.get(
        url
      );
    },
    participate : (roundId, competitionId) => {
        return axios.post(`${BASE_URL}/api/users/rounds/${roundId}/competitions/${competitionId}`);
    },
    saveMatches : (currentRound) => {
        return axios.put(`${BASE_URL}/api/users/rounds/${currentRound.roundId}/matches`, currentRound.matches.filter(x => x.value != null && x.playerId));
    },
    sellPlayer : (playerId) => {
        return axios.delete(`${BASE_URL}/api/users/players/${playerId}`);
    },
    buyPlayer : (playerId) => {
        return axios.post(`${BASE_URL}/api/users/players/${playerId}`);
    },
    searchPlayers : (params) => {
        return axios.get(`${BASE_URL}/api/players`, {
            params: params,
          });
    },
    getPlayersTeams : () => {
        return axios.get(`${BASE_URL}/api/players/teams`);
    },
    getStatistics: (index) => {
        return axios.get(`${BASE_URL}/api/league/${index}`);
    }
}
export default Api;