import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import Api from '../Api';
import Carousel from 'react-spring-3d-carousel';
import { v4 as uuidv4 } from 'uuid';
import { config } from 'react-spring';
import { useSwipeable } from 'react-swipeable';
// PlayerCard component: Display individual player details
const PlayerCard = ({ player, isTopPlayer, handleSwipe }) => {
  const cardStyle = {
    position: 'relative',
    margin: '10px',
    padding: '20px',
    textAlign: 'center',
    border: '1px solid #ccc',
    borderRadius: '8px',
    width: '150px',
    height: '150px',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  };

  const imgStyle = {
    width: '80px',
    height: '80px',
    objectFit: 'cover',
    border: isTopPlayer ? '2px solid gold' : 'none',
    borderRadius: '50%',
  };

  const logoStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    objectFit: 'cover',
  };

  return (
    <div style={cardStyle} onClick={handleSwipe}>
      <div>
        <img
          src={`players/${player.id}.png`}
          alt={player.name}
          style={imgStyle}
        />
        <img
          src={`logos/${player.teamId}.png`}
          alt={player.teamId}
          style={logoStyle}
        />
      </div>
      <h4>{player.name}</h4>
      <p>Goal Points: {player.goalPoints}</p>
    </div>
  );
};

// PlayerSetRow component: Now with 3D carousel and button below
const PlayerSetRow = ({ set, onSelectSet }) => {
  const sortedPlayers = set.players;
  const [currentSlide, setCurrentSlide] = useState(0); 

  const handleSwipeLeft = () => {
    setCurrentSlide((prev) => (prev + 1) % sortedPlayers.length); // перемещение вправо
  };

  const handleSwipeRight = () => {
    setCurrentSlide((prev) => (prev - 1 + sortedPlayers.length) % sortedPlayers.length); // перемещение влево
  };
  
  const rowStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '40px', // More margin for better separation between rows
    paddingBottom: '20px',
  };

  const buttonStyle = {
    marginTop: '90px',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '14px',
  };

  const carouselContainerStyle = {
    width: '80%',
    marginBottom: '20px',
  };

  const select = async (id) => {
    await Api.selectBundle(id);
    window.location.href = '/new-user';
  };

  // Prepare player cards for the 3D carousel
  const slides = sortedPlayers.map((player, index) => ({
    key: uuidv4(),
    content: <PlayerCard key={player.id} player={player} isTopPlayer={index === 0} />,
  }));
  return (
    <div style={rowStyle} onClick={handleSwipeLeft}>
      <div style={carouselContainerStyle}>
        <Carousel
          slides={slides}
          goToSlide={currentSlide}
          offsetRadius={2}
          showNavigation={false}
          animationConfig={config.gentle}
          
        />
      </div><br/>
      <button style={buttonStyle} onClick={() => select(set.id)}>
        Выбрать команду
      </button><hr style={{width:"100%"}}/><br/><br/><br/><br/>
    </div>
  );
};

// Bundles component: Main component to fetch and display player sets
const Bundles = () => {
  const { user, initializeAuth } = useContext(AuthContext);
  const [playerSets, setPlayerSets] = useState([]);
  const [loading, setLoading] = useState(true);
  // Fetch data from API
  useEffect(() => {
    const fetchBundles = async () => {
      try {
        await initializeAuth();
        const response = await Api.getBundles();
        setPlayerSets(response.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    fetchBundles();
  }, []);

  if (!user || loading) return <p>Loading...</p>;

  const appStyle = {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  };

  return (
    <div>
        <h1 style={{textAlign:"center"}}>Выберите команду</h1><br/><br/><br/><br/>
      <div style={appStyle}>
        {playerSets.map((set) => (
          <PlayerSetRow key={set.id} set={set} />
        ))}
      </div>
    </div>
    
  );
};

export default Bundles;