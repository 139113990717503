import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import { AuthContext } from '../context/AuthContext';
const RoundMatchForm = () => {
    const { user } = useContext(AuthContext);
    const [rounds, setRounds] = useState([]);
    const [selectedRoundId, setSelectedRoundId] = useState('');
    const [matches, setMatches] = useState([]);
    const [selectedMatchId, setSelectedMatchId] = useState('');
    const [homeScore, setHomeScore] = useState(0);
    const [awayScore, setAwayScore] = useState(0);
    const [isFinished, setIsFinished] = useState(false);
    const [isCanceled, setIsCanceled] = useState(false);

    // Загружаем раунды при первом рендере с помощью axios
    useEffect(() => {
        axios.get('http://localhost:5006/api/rounds')
            .then(response => {
                setRounds(response.data);
            })
            .catch(error => console.error('Ошибка при загрузке данных:', error));
        
    }, []);
    if (!user) {
        return <div>Loading...</div>;
    }

    // Обновляем список матчей при выборе раунда
    const handleRoundChange = (e) => {
        const roundId = e.target.value;
        setSelectedRoundId(roundId);
        const selectedRound = rounds.find(round => round.id === roundId);
        if (selectedRound) {
            setMatches(selectedRound.matches);
        } else {
            setMatches([]);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const requestData = {
            roundId: selectedRoundId,
            matchId: selectedMatchId,
            isCanceled: isCanceled,
            homeScore: parseInt(homeScore, 10),
            awayScore: parseInt(awayScore, 10),
            isFinished: isFinished,
        };

        // Отправляем POST-запрос на сервер
        axios.post('http://localhost:5006/api/rounds/result', requestData)
            .then(response => {
                console.log('Успех:', response.data);
            })
            .catch(error => console.error('Ошибка при отправке данных:', error));
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label htmlFor="round-select">Выберите Раунд (ID - Дата окончания ставок):</label>
                <select id="round-select" value={selectedRoundId} onChange={handleRoundChange}>
                    <option value="">Выберите раунд</option>
                    {rounds.map(round => (
                        <option key={round.id} value={round.id}>
                            {round.title} - {new Date(round.endBidDate).toLocaleString()}
                        </option>
                    ))}
                </select>
            </div>

            {matches.length > 0 && (
                <div>
                    <label htmlFor="match-select">Выберите матч (Home - Away):</label>
                    <select id="match-select" value={selectedMatchId} onChange={e => setSelectedMatchId(e.target.value)}>
                        <option value="">Выберите матч</option>
                        {matches.map(match => (
                            <option key={match.matchId} value={match.matchId}>
                                {match.homeTitle} - {match.awayTitle}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            <div>
                <label htmlFor="homeScore">Home Score:</label>
                <input
                    type="number"
                    id="homeScore"
                    value={homeScore}
                    onChange={e => setHomeScore(e.target.value)}
                />
            </div>

            <div>
                <label htmlFor="awayScore">Away Score:</label>
                <input
                    type="number"
                    id="awayScore"
                    value={awayScore}
                    onChange={e => setAwayScore(e.target.value)}
                />
            </div>

            <div>
                <label>
                    <input
                        type="checkbox"
                        checked={isFinished}
                        onChange={e => setIsFinished(e.target.checked)}
                    />
                    Is Finished
                </label>
            </div>

            <div>
                <label>
                    <input
                        type="checkbox"
                        checked={isCanceled}
                        onChange={e => setIsCanceled(e.target.checked)}
                    />
                    Is Canceled
                </label>
            </div>

            <button type="submit">Отправить результат</button>
        </form>
    );
};

export default RoundMatchForm;