import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import '../App.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { AuthContext } from '../context/AuthContext';
import Api from '../Api';

// Set Modal's root element
Modal.setAppElement('#root');

function CurrentRound() {
  const { user, initializeAuth } = useContext(AuthContext);
  const [userRoundsPage, setUserRoundsPage] = useState(1);
  const [opponents, setOpponents] = useState([]);
  const [currentRound, setCurrentRound] = useState({ matches: [] });
  const [selectedCompetition, setSelectedCompetition] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false); // State to control modal visibility
  const [selectedMatch, setSelectedMatch] = useState(null); // Store the currently selected match

  // Fetch the current round details
  const fetchCurrentRound = async () => {
    await initializeAuth();
    try {
      if (user && (user.players == null || user.players.length === 0)) {
        return window.location.href = '/bundles';
      }
      const { data } = await Api.getCurrentRound();
      setCurrentRound(data);
      await fetchOpponents(data.roundId);
    } catch (error) {
      console.error("Error fetching current round:", error);
    }
  };

  const getButtons = (match) => {
    let team1Btn = (<button class="team1-btn" onClick={() => setMatchResult(match, 1)} >{match.homeTitle}</button>);
    var team2Btn = (<button class="team2-btn" onClick={() => setMatchResult(match, 2)} >{match.awayTitle}</button>);
    let teamDrawBtn = (<button class="draw-btn" onClick={() => setMatchResult(match, 0)} >Draw</button>)
    if (match.value == 1) {
      team1Btn = (<button class="team1-btn selected" onClick={() => setMatchResult(match, 1)} >{match.homeTitle}</button>);
    } else if (match.value == 2) {
      team2Btn = (<button class="team2-btn selected" onClick={() => setMatchResult(match, 2)} >{match.awayTitle}</button>);
    } else if (match.value == 0) {
      teamDrawBtn = (<button class="draw-btn selected" onClick={() => setMatchResult(match, 0)} >Draw</button>)
    }
    return (<div class="prediction-buttons">{team1Btn}{teamDrawBtn}{team2Btn}</div>);
  }

  const getMatchPoints = (match) => {
    if (match.result) {
      if (match.result == match.value) {
        return <div className='match-points'>+{match.points} очков</div>;
      }
      return <div className='zero-points'>0 очков</div>;
    }
    return '';
  }

  const fetchOpponents = async (roundId, competitionId) => {
    try {
      const response = await Api.getOpponents(roundId, competitionId, userRoundsPage);
      setOpponents(response.data);
    } catch (error) {
      console.error("Error fetching opponents:", error);
    }
  };

  const clearPlayer = (match) => {
    const updatedMatches = currentRound.matches.map(m =>
      m.matchId === match.matchId ? { ...m, playerId: null } : m
    );
    setCurrentRound({ ...currentRound, matches: updatedMatches });
    openModal(match);
  }

  const getPlayer = (match) => {
    if (!match.playerId) {
      return <button className='select-player-button' onClick={() => openModal(match)}>Select Player</button>
    }
    var player = user.players.find(x => x.id == match.playerId);
    if (!player) {
      clearPlayer(match);
      return <button className='select-player-button' onClick={() => openModal(match)}>Select Player</button>
    }
    return <div style={{paddingLeft:'15px'}}><div style={{display:'flex', alignItems:'center'}}>
          <img src={`players/${player.id}.png`} alt={player.name} style={{ width: '50px', height: '50px', marginRight:'10px' }} />
          <div><div style={{textAlign:'left'}}>
          <b>{player.name}</b></div><div style={{textAlign:'left'}}>+{player.goalPoints} points</div></div>
      </div><br/><button className='select-player-button' onClick={() => clearPlayer(match)}>Change player</button></div>;
  }

  useEffect(() => {
    fetchCurrentRound();
  }, []); 
  const setMatchResult = (match, result) => {
    const updatedMatches = currentRound.matches.map(m => 
      m.matchId === match.matchId ? { ...m, value: result } : m
    );
    setCurrentRound({ ...currentRound, matches: updatedMatches });
  };
  const handleChangeCompetition = (event) => {
    const selectedId = event.target.value;
    fetchOpponents(currentRound.roundId, selectedId);
  };

  const canSelect = (player) => {
    return !currentRound.matches.find(m => m.playerId === player.id);
  } 
  
  const getCompetitionStatus = (competition) => {
    const exists = currentRound.competitions.find(x => x.competitionId == competition.competitionId);
    if (exists && exists.isParticipant) {
      if (exists.place && exists.points > 0) {
        return <div><div>Вы участвуете</div><div>Вы на {exists.place} месте</div></div>;
      }
      return <div>Вы участвуете</div>;
    }
    return <button onClick={() => participate(competition.competitionId)}>Учавствовать</button>;
  }
  const getMatchClass = (match) => {
    if (match.result) {
      if (match.result == match.value) {
        return 'match-card gold';
      }
    }
    return 'match-card';
  }
  const participate = async (competitionId) => {
    await Api.participate(currentRound.roundId, competitionId);
    window.location = window.location;
  }
  // Group players by position for the modal
  const groupPlayersByPosition = (players) => {
    const grouped = players.reduce((acc, player) => {
      const positionGroup = acc.find((group) => group.position === player.position);
      if (positionGroup) {
        positionGroup.options.push(player);
      } else {
        acc.push({
          position: player.position,
          options: [player],
        });
      }
      return acc;
    }, []);
    return grouped;
  };

  const save = async () => {
    var matches = currentRound.matches;
    try 
    {
      await Api.saveMatches(currentRound);
      window.location = window.location;
    }
    catch 
    {
      alert('Error');
    }
  };

  // Handle player selection from the modal
  const handlePlayerSelect = (player) => {
    const updatedMatches = currentRound.matches.map(m =>
      m.matchId === selectedMatch.matchId ? { ...m, playerId: player.id } : m
    );
    setCurrentRound({ ...currentRound, matches: updatedMatches });
    setModalIsOpen(false); // Close modal after selecting a player
  };

  // Open modal with selected match
  const openModal = (match) => {
    setSelectedMatch(match);
    setModalIsOpen(true);
  };

  // Render modal content with players grouped by position
  const renderPlayerModalContent = () => {
    if (!selectedMatch || !user || !user.players) return null;
    const groupedPlayers = groupPlayersByPosition(user.players);
    return (
      <div>
        <h2>Select a Player for {selectedMatch.homeTitle} vs {selectedMatch.awayTitle}</h2>
        <div style={{height: '60vh', overflowY:'scroll' }}>
        {groupedPlayers.map((group, index) => (
          <div key={index}>
            <h3>Position: {group.position}</h3>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {group.options.map((player) => canSelect(player) ? (
                <div
                  key={player.id}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: '10px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handlePlayerSelect(player)}
                >
                  <img src={`players/${player.id}.png`} alt={player.name} style={{ width: 60, height: 60 }} />
                  <p>{player.name}</p>
                  <p>+{player.goalPoints} points</p>
                </div>
              ) : '')}
            </div>
          </div>
        ))}</div>
      </div>
    );
  };

  // Match rendering with "Select Player" button that opens the modal
  const renderMatches = () => {
    return currentRound.matches.map((match, index) => (
      <div key={index}  class={getMatchClass(match)}>
        <div className="team-row">
          <img src={`/logos/${match.homeId}.png`} alt={`${match.homeId}`} />
          <h2>{match.homeTitle} vs {match.awayTitle}</h2>
          <img src={`/logos/${match.awayId}.png`} alt={`${match.awayId}`} />
        </div>
        <div className="team-score">
          <span className="home-score">{match.homeScore}</span>
          <span className='match-date'>{getMatchPoints(match)}{match.date}</span>
          <span className="away-score">{match.awayScore}</span>
        </div>
        {getButtons(match)}
        {getPlayer(match)}
      </div>
    ));
  };

  // Return JSX
  return (
    <Tabs>
      <TabList>
        <Tab>Матчи</Tab>
        <Tab>Турниры</Tab>
        <Tab>Соперники</Tab>
      </TabList>

      <TabPanel>
        <div className="match-container">
          {currentRound.matches.length > 0 ? renderMatches() : <p>Loading matches...</p>}
          <br />
          <button className="submit-btn" onClick={() => save()}>Submit Predictions</button>
          <button className="submit-btn">Сохранить</button>
        </div>
      </TabPanel>

      <TabPanel>
        <div>
            {currentRound.competitions && currentRound.competitions.length > 0 ? (
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                {currentRound.competitions.map((competition) => (
                    <div
                    key={competition.id}
                    style={{
                        border: '1px solid #ddd',
                        padding: '10px',
                        borderRadius: '8px',
                        width: '250px',
                        textAlign: 'center',
                    }}
                    >
                    <h3>{competition.title}</h3>
                    {getCompetitionStatus(competition)}
                    </div>
                ))}
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
      </TabPanel>
      
      <TabPanel>
        <div>
        <h1>Выберите соревнование</h1>

        {/* Dropdown для выбора соревнования */}
        <select value={selectedCompetition} onChange={handleChangeCompetition}>
          <option value="">Выберите...</option>
          {(currentRound.competitions && currentRound.competitions.length > 0 ? currentRound.competitions.map((competition) => (
            <option key={competition.competitionId} value={competition.competitionId}>
              {competition.title}
            </option>
          )) : '')}
        </select>

        {/* Таблица с результатами */}
        {opponents && (
          <table border="1" style={{ marginTop: '20px', width: '100%' }}>
            <thead>
              <tr>
                <th>UserId</th>
                <th>Points</th>
                <th>Goals Count</th>
              </tr>
            </thead>
            <tbody>
              {opponents.map((user, index) => (
                <tr key={index}>
                  <td>{user.userName}</td>
                  <td>{user.points}</td>
                  <td>{user.goals ? user.goals.length : 0}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      </TabPanel>

      {/* Modal for selecting players */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Player Selection"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxHeight: '80%',
            overflow: 'auto',
          },
        }}
      >
        {renderPlayerModalContent()}
        <button className='select-player-button' onClick={() => setModalIsOpen(false)}>Close</button>
      </Modal>
    </Tabs>
  );
}

export default CurrentRound;