import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import Api from '../Api';

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Начальное состояние null для неавторизованного пользователя

  const fetchUser = async () => {
    try {
      if (!user) {
        const response = await Api.getUser();
        console.log(response.data);
        if (window.location.pathname != '/' && window.location.pathname != '/telegram-login' && window.location.pathname != '/bundles' && (response.data.players == null || response.data.players == undefined || response.data.players < 10)) {
            window.location.href = '/bundles';
        }
        setUser(response.data);
      }
    } catch (error) {
        console.warn('Token is invalid, clearing token');
        localStorage.removeItem('token');
        setUser(null);
        if (window.location.pathname != '/' && window.location.pathname != '/telegram-login') {
          window.location.href = '/';
        }
    }
  };

  const initializeAuth = async () => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      console.log(`Using stored token: ${storedToken}`);
      axios.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
      await fetchUser(storedToken); // Пробуем загрузить пользователя с существующим токеном
    } else {
      console.log(window.location);
      if (window.location.pathname != '/' && window.location.pathname != '/telegram-login') {
        window.location.href = '/';
      }
    }
  };

  useEffect(() => {
    initializeAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ user, initializeAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };