import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import CurrentRound from './components/CurrentRound';
import Season from './components/Season';
import Team from './components/Team';
import Login from './components/Login';
import CreateRound from './components/CreateRound';
import RoundMatchForm from './components/RoundMatchForm';
import Bundles from './components/Bundles';
const App = () => {
  return (
    <div class="body">
        <div class="content">
            <Router>
              <Routes>
                <Route path="/new-user" element={<CurrentRound />} />  
                <Route path="/current-round" element={<CurrentRound />} />
                <Route path="/season" element={<Season />} />
                <Route path="/team" element={<Team />} />
                <Route path="/" element={<Login />} />
                <Route path="/create-round" element={<CreateRound />} />
                <Route path="/round-match" element={<RoundMatchForm />} />
                <Route path="/bundles" element={<Bundles />} />
              </Routes>
            </Router>
        </div>
    {(window.location.pathname == '/bundles' ? '' :<div class="bottom-menu">
          <div class="menu-button" onClick={() => window.location.href = ('/current-round')}>
            Туры
            <span>&#x1F4CA;</span> 
          </div>
          <div class="menu-button" onClick={() => window.location.href = ('/season')}>
            Лига
            <span>&#x1F3C6;</span> 
          </div>
          <div class="menu-button" onClick={() => window.location.href = ('/team')}>
            Команда
            <span>&#x1F3C3;</span> 
          </div>
          <div class="menu-button">
            Профиль
            <span>&#x1F464;</span> 
          </div>
        </div>)}
        
    </div>
    
  );
};

export default App;

/*
<table width={"100%"}>
      <tr>
        <td colSpan={3}>
          <div style={{ height: '93vh', overflowY: 'scroll' }}>
            
          </div>
        </td>
      </tr>
      <tr>
        <td style={{width:"33%"}}>
          <button onClick={() => window.location.href = "/"} href="/" style={{"width":"100%", "height":"50px"}}>Раунд</button>
        </td>
        <td style={{width:"33%"}}>
          <button onClick={() => window.location.href = "/season"} href="/season" style={{"width":"100%", "height":"50px"}}>Сезон</button>
        </td>
        <td style={{width:"33%"}}>
          <button onClick={() => window.location.href = "/team"} href="/team" style={{"width":"100%", "height":"50px"}}>Команда</button>
        </td>
      </tr>
    </table>
    </div>
    */