import { AuthContext } from '../context/AuthContext';
import React, { useState, useEffect, useContext } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Market from './Market';

import Api from '../Api';
function Team() {
    
  const { user, initializeAuth } = useContext(AuthContext);
    const sell = async (playerId) => {
        try 
        {
            await Api.sellPlayer(playerId);
            window.location = window.location;
        }
        catch {
            alert('Нельзя продать игрока');
        }
    }
    const init = async () => {
        await initializeAuth();
    }
    useEffect(() => {
        init();
      }, []); 
      if (user == undefined) {
        return 'Loading...';
      }
    return (<Tabs>
        <TabList>
          <Tab>Моя команда</Tab>
          <Tab>Маркет</Tab>
        </TabList>
        <TabPanel>
            <div>
            {user.players && user.players.length > 0 ? (
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                {user.players.map((player) => (
                    <div
                    key={player.id}
                    style={{
                        border: '1px solid #ddd',
                        padding: '10px',
                        borderRadius: '8px',
                        width: '250px',
                        textAlign: 'center',
                    }}
                    >
                    <img src={'players/' + player.id + '.png'}/>
                    <h3>{player.name}</h3>
                    <p>Позиция: {player.position === 4 ? 'Нападающий' : player.position === 3 ? 'Полузащитник' : 'Защитник'}</p>
                    <p>Цена: {player.price.toLocaleString()} €</p>
                    <p>Очки за голы: {player.goalPoints}</p>
                    <button onClick={() => sell(player.id)}>Продать</button>
                    </div>
                ))}
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    </TabPanel>
    <TabPanel>
        <Market/>
    </TabPanel></Tabs>);
}

export default Team;