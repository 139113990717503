import { AuthContext } from '../context/AuthContext';
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import Api from '../Api';
function Market() {
  const { user, initializeAuth } = useContext(AuthContext);
  const [players, setPlayers] = useState([]); // Список игроков
  const [teams, setTeams] = useState([]); // Список команд
  const [filters, setFilters] = useState({
    name: '',
    teamId: '',
    position: '',
    maxPrice: '',
    orderByAsc: false,
  });

  // Получаем список команд
  const fetchTeams = async () => {
    try {
      const { data } = await Api.getPlayersTeams();
      setTeams(data);
    } catch (error) {
      console.error('Ошибка получения списка команд:', error);
    }
  };

  // Получаем список игроков по фильтрам
  const fetchPlayers = async () => {
    await initializeAuth();
    try {
      const { name, teamId, position, maxPrice, orderByAsc } = filters;
      var params = {
        Name: name,
        TeamId: teamId,
        OrderByAsc: orderByAsc,
      }
      if (maxPrice) {
        params.MaxPrice = maxPrice;
      }
      if (position) {
        params.Position = position;
      }
      const response = await Api.searchPlayers(params);
      setPlayers(response.data);
    } catch (error) {
      console.error('Ошибка получения игроков:', error);
    }
  };

  useEffect(() => {
    fetchTeams(); // Получаем команды при загрузке страницы
    fetchPlayers();
  }, []);

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handleSortChange = (e) => {
    setFilters({
      ...filters,
      orderByAsc: e.target.value === 'asc',
    });
  };

  const buy = async (playerId) => {
    await Api.buyPlayer(playerId);
    
    window.location = window.location;
  }

  const sell = async (playerId) => {
    await Api.sellPlayer(playerId);
    
    window.location = window.location;
  }

  const getButton = (playerId) => {
      var player = user.players.find(p => p.id === playerId);
      if (!player) {
        return (<button onClick={() => buy(playerId)}>Купить</button>)
      } else {
        return (<button onClick={() => sell(playerId)}>Продать</button>)
      }
  }

  const handleSearch = (e) => {
    e.preventDefault();
    fetchPlayers(); // Получаем игроков при нажатии на кнопку "Поиск"
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ padding: '20px' }}>
      <h2>Рынок игроков</h2>

      {/* Форма фильтрации */}
      <form onSubmit={handleSearch} style={{ marginBottom: '20px' }}>
        <div>
          <label>Имя игрока:</label>
          <input
            type="text"
            name="name"
            value={filters.name}
            onChange={handleFilterChange}
            placeholder="Введите имя игрока"
          />
        </div>

        <div>
          <label>Команда:</label>
          <select name="teamId" value={filters.teamId} onChange={handleFilterChange}>
            <option value="">Все команды</option>
            {teams.map((team) => (
              <option key={team.teamId} value={team.teamId}>
                {team.teamName}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label>Позиция:</label>
          <select name="position" value={filters.position} onChange={handleFilterChange}>
            <option value="">Все позиции</option>
            <option value="4">Нападающий</option>
            <option value="3">Полузащитник</option>
            <option value="2">Защитник</option>
          </select>
        </div>

        <div>
          <label>Максимальная цена:</label>
          <input
            type="number"
            name="maxPrice"
            value={filters.maxPrice}
            onChange={handleFilterChange}
            placeholder="Введите максимальную цену"
          />
        </div>

        <div>
          <label>Сортировать по цене:</label>
          <select name="orderByAsc" value={filters.orderByAsc ? 'asc' : 'desc'} onChange={handleSortChange}>
            <option value="asc">По возрастанию</option>
            <option value="desc">По убыванию</option>
          </select>
        </div>

        <button type="submit">Поиск</button>
      </form>

      {/* Список игроков */}
      {players.length > 0 ? (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
          {players.map((player) => (
            <div
              key={player.id}
              style={{
                border: '1px solid #ddd',
                padding: '10px',
                borderRadius: '8px',
                width: '250px',
                textAlign: 'center',
              }}
            >
              <img src={'players/' + player.id + '.png'}/>
              <h3>{player.name}</h3>
              <p>Команда: {teams.find((t) => t.teamId === player.teamId)?.teamName}</p>
              <p>Позиция: {player.position === 4 ? 'Нападающий' : player.position === 3 ? 'Полузащитник' : 'Защитник'}</p>
              <p>Цена: {player.price.toLocaleString()} €</p>
              <p>Очки за голы: {player.goalPoints}</p>
              {getButton(player.id)}
            </div>
          ))}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default Market;