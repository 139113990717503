import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

const CreateRound = () => {
    
  const { user, initializeAuth } = useContext(AuthContext);
  const [matches, setMatches] = useState([]);
  const [selectedMatches, setSelectedMatches] = useState([]);

  // Получаем список матчей
  useEffect(() => {
    const fetchMatches = async () => {
      try {
        await initializeAuth();
        const response = await axios.get('http://localhost:5006/api/rounds/future/matches?Refresh=false');
        setMatches(response.data);
      } catch (error) {
        console.error('Ошибка при получении матчей:', error);
      }
    };
    fetchMatches();
  }, []);

  if (!user) {
    return <div>Loading...</div>;
  }
  // Обработка выбора матча через чекбокс
  const handleCheckboxChange = (match) => {
    if (selectedMatches.includes(match)) {
      setSelectedMatches(selectedMatches.filter((m) => m.id !== match.id));
    } else {
      setSelectedMatches([...selectedMatches, match]);
    }
  };

  // Обработка отправки данных
  const handleCreateRound = async () => {
    const season = '2024';  // Здесь можно изменить на нужный сезон
    const title = 'Новый Раунд'; // Здесь можно изменить название раунда
    const startDate = new Date().toISOString(); // Указываем текущую дату или можно использовать дату из матчей

    const requestData = {
      season: season,
      title: title,
      matches: selectedMatches.map((match) => ({
        matchId: match.id,
        homeId: match.homeId,
        awayId: match.awayId,
        date: match.date
      }))
    };

    try {
      const response = await axios.post('http://localhost:5006/api/rounds', requestData);
      console.log('Раунд успешно создан:', response.data);
    } catch (error) {
      console.error('Ошибка при создании раунда:', error);
    }
  };

  return (
    <div>
      <h1>Список матчей</h1>
      <table>
        <thead>
          <tr>
            <th>Выбрать</th>
            <th>Матч</th>
            <th>Дата</th>
            <th>Rate</th>
            <th>HomeRate</th>
            <th>DrawRate</th>
            <th>AwayRate</th>
          </tr>
        </thead>
        <tbody>
          {matches.map((match) => (
            <tr key={match.id}>
              <td align='center'>
                <input
                  type="checkbox"
                  checked={selectedMatches.includes(match)}
                  onChange={() => handleCheckboxChange(match)}
                />
              </td>
              <td align='center'>{match.title}</td>
              <td align='center'>{new Date(match.date).toLocaleString()}</td>
              <td align='center'>{match.weight}</td>
              <td align='center'>{match.homeRate}</td>
              <td align='center'>{match.drawRate}</td>
              <td align='center'>{match.awayRate}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={handleCreateRound}>Создать раунд</button>
    </div>
  );
};

export default CreateRound;