import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Api from '../Api';

const Login = () => {
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();
  const loginTelegram = async (initData) => {
  const response = await Api.loginTelegram(initData);

    // Получаем токен и сохраняем в localStorage
    const token = response.data;
    localStorage.setItem('token', token);

    
    window.location.href = '/current-round';
  }
  // Получение списка пользователей с API
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await Api.getUsers();
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    if (window.Telegram && window.Telegram.WebApp) {
      // Инициализация Telegram WebApp
      const webApp = window.Telegram.WebApp;
      
      if (webApp.initData != '') {
        webApp.ready();
        loginTelegram(webApp.initData);
      } else {
        fetchUsers();
      }
    } else {
      fetchUsers();
    }
  }, []);

  // Обработка отправки запроса логина
  const handleLogin = async () => {
    if (selectedUserId) {
      try {
        const response = await Api.login(selectedUserId);

        // Получаем токен и сохраняем в localStorage
        const token = response.data;
        localStorage.setItem('token', token);

        
        window.location.href = '/current-round';
      } catch (error) {
        console.error('Error logging in:', error);
      }
    }
  };

  // Обработка создания нового пользователя
  const handleCreateUser = async () => {
    if (userName) {
      try {
        const response = await Api.addUser(userName);

        // Получаем ID нового пользователя
        const newUserId = response.data;

        // Логика аналогична логину: получаем токен, сохраняем и редиректим
        const tokenResponse = await Api.login(newUserId);
        const token = tokenResponse.data;
        localStorage.setItem('token', token);
        window.location.href = '/current-round';
      } catch (error) {
        console.error('Error creating user:', error);
      }
    }
  };

  return (users != null && users.length > 0 ?
    <div>
      <div>
        <h2>Select User</h2>
        <select onChange={(e) => setSelectedUserId(e.target.value)} value={selectedUserId}>
          <option value="" disabled>Select a user</option>
          {users.map((user) => (
            <option key={user.id} value={user.id}>
              {user.title || user.id} {/* Отображаем title или id */}
            </option>
          ))}
        </select>
        <button onClick={handleLogin} disabled={!selectedUserId}>
          Login
        </button>
      </div>

      {/* Вторая часть страницы: Создание нового пользователя */}
      <div>
        <h2>Create New User</h2>
        <input
          type="text"
          placeholder="Enter username"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />
        <button onClick={handleCreateUser} disabled={!userName}>
          Create User
        </button>
      </div>
    </div> : 'Loading...'
  );
};

export default Login;